import {useTranslation} from 'react-i18next'

import {StepForm} from '../../../Components/SelfOnBoarding/StepForm'
import {useGlobalState} from '../../../hooks/useGlobalState'
import {AccessLevelStep} from '../steps/accessLevel/AccessLevelStep'
import {CompanyStep} from '../steps/company/CompanyStep'
import {RegistrationStep} from '../steps/registration/RegistrationStep'
import {SummaryStep} from '../steps/summary/SummaryStep'

import {StepContainer} from './StepContainer'

type FormStepperProps = {onSubmit: () => void}

export const FormStepper = ({onSubmit}: FormStepperProps) => {
  const {t} = useTranslation()
  const {globalState, setGlobalState} = useGlobalState()

  const index = globalState.requestAccessStepperIndex

  const yourDetailsForm = <>Your details</>

  const handleSummaryEditClick = (index: number) => {
    setGlobalState((g) => ({
      ...g,
      requestAccessStepperIndex: index
    }))
  }

  const steps = [
    {
      stepComponent: <RegistrationStep />,
      stepNumber: 1
    },
    {
      stepComponent: <CompanyStep />,
      submitText: t('authenticator.requestAccessSelfOnboarding.companyStep.form.setAccessLevel'),
      stepNumber: 2
    },
    {
      stepComponent: <AccessLevelStep />,
      submitText: t('authenticator.requestAccessSelfOnboarding.accessLevelStep.fillDetails'),
      stepNumber: 3
    },
    {
      stepComponent: yourDetailsForm,
      submitText: t('authenticator.requestAccessSelfOnboarding.userDetailsStep.goToSummary'),
      stepNumber: 4
    },
    {
      stepComponent: (
        <SummaryStep
          onCompanyEdit={() => handleSummaryEditClick(1)}
          onAccessLevelEdit={() => handleSummaryEditClick(2)}
          onDetailsEdit={() => handleSummaryEditClick(3)}
        />
      ),
      submitText: t('authenticator.requestAccessSelfOnboarding.summaryStep.submit'),
      onSubmit: onSubmit,
      stepNumber: 5
    }
  ]

  return (
    <>
      {steps.map(
        (step) =>
          step.stepNumber === index + 1 && (
            <StepContainer
              key={index}
              form={step.stepComponent}
              stepForm={<StepForm submitText={step.submitText} onSubmit={step.onSubmit} />}
            />
          )
      )}
    </>
  )
}
