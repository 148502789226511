import {Box, Button, ButtonProps} from '@mui/material'
import clsx from 'clsx'
import {useTranslation} from 'react-i18next'

import {requestAccessMaxStepIndex} from '../../constants'
import {useGlobalState} from '../../hooks/useGlobalState'
import {useRafStyles} from '../../Pages/RequestAccessSelfOnboarding/styles'

type StepFormProps = ButtonProps & {
  submitText?: string
  onSubmit?: () => void
}

export const StepForm = ({submitText, onSubmit, ...otherProps}: StepFormProps) => {
  const {globalState, setGlobalState} = useGlobalState()
  const {classes} = useRafStyles()
  const index = globalState.requestAccessStepperIndex
  const {t} = useTranslation()

  const setRequestAccessStep = (newStepIndex: number) => {
    if (newStepIndex > requestAccessMaxStepIndex) {
      return
    }
    setGlobalState((g) => ({
      ...g,
      requestAccessStepperIndex: newStepIndex
    }))
  }

  const handleBack = () => {
    setRequestAccessStep(index - 1)
  }

  const handleSubmit = () => {
    setRequestAccessStep(index + 1)
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <Box style={{display: 'flex', gap: '12px', justifyContent: 'center'}}>
      {index > 0 ? (
        <Button onClick={handleBack} className={clsx(classes.secondaryButton, classes.backButton)}>
          {t('authenticator.requestAccessSelfOnboarding.goBack')}
        </Button>
      ) : null}
      {submitText && (
        <Button
          type="submit"
          onClick={handleSubmit}
          data-test-id={`raf-step-${index}-submit`}
          style={{backgroundColor: 'rgba(1, 106, 212, 1)', color: '#FFFFFF', whiteSpace: 'nowrap'}}
          {...otherProps}
        >
          {submitText}
        </Button>
      )}
    </Box>
  )
}
