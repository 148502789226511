import {FormToggle, InputMessage} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Divider, TextField, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {RequestAccessSelfOnboardingFormData} from '../../RequestAccessSelfOnboarding.types'
import {useRafStyles} from '../../styles'

import {useStyles} from './AccessLevelStep.styles'
import {AdditionalDetails} from './AdditionalDetails'

const MAX_EMAIL_CHARACTERS_LENGTH = 2000

export const AccessLevelStep = () => {
  const formMethods = useFormContext<RequestAccessSelfOnboardingFormData>()
  const {t} = useTranslation()
  const {classes: generalClasses} = useRafStyles()
  const {classes} = useStyles()
  const currentPermissions = formMethods.watch('permissions')
  const isOrderPlacerSelected = currentPermissions['canCreateAndChangeOrders']
  const isViewInvoicesToggled = currentPermissions['canSeeInvoices']

  const accessAllProjectsSelected = formMethods.watch('allProjectsAccess')
  const wordsLength = formMethods.watch('comment')?.length || 0

  useEffect(() => {
    formMethods.resetField('deliveryTicketNr')
    formMethods.resetField('deliveryTicketDate')
    formMethods.resetField('invoiceNr')
    formMethods.resetField('invoiceNetTotal')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewInvoicesToggled])

  return (
    <Box style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '12px'}}>
      <Typography className={generalClasses.title} style={{marginBottom: '32px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.title')}
      </Typography>
      <Typography className={generalClasses.subTitle} style={{marginBottom: '16px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.permissionsTitle')}
      </Typography>
      {formMethods.formState.defaultValues?.permissions &&
        Object.keys(formMethods.formState.defaultValues?.permissions).map((permissionKey) => (
          <Controller
            key={permissionKey}
            name={`permissions.${permissionKey}` as keyof RequestAccessSelfOnboardingFormData}
            control={formMethods.control}
            render={({field}) => (
              <FormToggle
                toggleKey={permissionKey}
                label={t(
                  `authenticator.requestAccessSelfOnboarding.accessLevelStep.permissionToggles.${permissionKey}`
                )}
                disabled={permissionKey === 'canSeeOrderAndDeliveries'}
                data-test-id={`access-level-toggle-${permissionKey}`}
                checked={!!currentPermissions?.[permissionKey]}
                onChange={(checked) => field.onChange(checked)}
              />
            )}
          />
        ))}
      <Controller
        name="comment"
        control={formMethods.control}
        render={({field}) => (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '32px',
              marginTop: '32px'
            }}
          >
            <TextField
              {...field}
              multiline={true}
              minRows={4}
              className={classes.multilineText}
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              InputLabelProps={{shrink: true}}
              label={t('authenticator.requestAccessSelfOnboarding.accessLevelStep.commentLabel')}
              placeholder={t(
                'authenticator.requestAccessSelfOnboarding.accessLevelStep.commentDescription'
              )}
              data-test-id="access-level-comment"
            />
            <span
              style={{
                placeSelf: 'flex-end',
                fontSize: '12px',
                color: formMethods.formState.errors?.comment?.message && 'rgba(218, 9, 1, 1)'
              }}
            >{`${
              wordsLength > MAX_EMAIL_CHARACTERS_LENGTH
                ? t(`authenticator.errorMessages.${formMethods.formState.errors?.comment?.message}`)
                : ''
            } ${wordsLength}/${MAX_EMAIL_CHARACTERS_LENGTH}`}</span>
          </Box>
        )}
      />
      <Typography className={generalClasses.subTitle} style={{marginBottom: '8px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.accessAllTitle')}
      </Typography>
      <Typography className={generalClasses.description} style={{marginBottom: '16px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.accessAllDescription')}
      </Typography>
      <Controller
        name="allProjectsAccess"
        control={formMethods.control}
        render={({field}) => (
          <FormToggle
            toggleKey="all-projects-access"
            label={t('authenticator.requestAccessSelfOnboarding.accessLevelStep.accessAllLabel')}
            data-test-id={'access-to-all-projects-toggle'}
            checked={field.value as boolean}
            onChange={field.onChange}
          />
        )}
      />
      {!isOrderPlacerSelected && (
        <>
          <AdditionalDetails />
          <Divider style={{borderColor: '#D1D9E0', marginBottom: '24px'}} />
        </>
      )}
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: isOrderPlacerSelected ? '16px' : 0
        }}
      >
        <Typography className={generalClasses.subTitle}>
          {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.onsiteAccessTitle')}
        </Typography>
        <Controller
          name="onsiteAccess"
          control={formMethods.control}
          render={({field}) => (
            <FormToggle
              toggleKey="onsite-access"
              label=""
              data-test-id={'onsite-access-toggle'}
              checked={field.value as boolean}
              onChange={field.onChange}
            />
          )}
        />
      </Box>
      <Typography className={generalClasses.description} style={{marginBottom: '24px'}}>
        {t('authenticator.requestAccessSelfOnboarding.accessLevelStep.onsiteAccessDescription')}
      </Typography>
      {accessAllProjectsSelected && (
        <InputMessage
          variant="warning"
          startIcon={<InfoOutlined style={{fontSize: '24px'}} />}
          message={t('authenticator.requestAccessSelfOnboarding.accessLevelStep.reviewNeeded')}
        />
      )}
    </Box>
  )
}
