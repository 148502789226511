export type RequestAccessSelfOnboardingFormData = {
  fullName: string
  permissions: PermissionType
  companyName: string
  market: string
  countryId: string
  customerIds: Customer[]
  email?: string
  mobileNumber?: string
  comment?: string
  allProjectsAccess?: boolean
  onsiteAccess?: boolean
  deliveryTicketNr?: string
  deliveryTicketDate?: string
  invoiceNr?: string
  invoiceNetTotal?: string
}

export type PermissionType = {
  [permission: string]: boolean
}

type Customer = {
  id: string
}

export enum RequestAccessRoles {
  ORDER_PLACER = 'ORDER_PLACER',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  SALES_AGENT = 'SALES_AGENT'
}

export const ContactUsUrl =
  'https://forms.office.com/pages/responsepage.aspx?id=BiSVVyivyEO03qTgb1dHbXPkKU6CsC5MsqIt5o24w4hUMzFHNjYwSVhYQTBVNFJMWEdKRlNRQjJSOCQlQCN0PWcu&route=shorturl'