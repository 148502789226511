import {CreationChannel, CreationProduct, Product} from '@hconnect/apiclient'
import {zodResolver} from '@hookform/resolvers/zod'
import {useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'

import {useAvailableCountries} from '../../Components/AccountForm/useCountries'
import {useGlobalState} from '../../hooks/useGlobalState'
import {calcDefaultLocale, getBrowserLanguage} from '../../utils'

import {FormStepper} from './components/FormStepper'
import {RequestAccessPayload, useRequestAccess} from './hooks/useRequestAccess'
import {
  PermissionType,
  RequestAccessSelfOnboardingFormData
} from './RequestAccessSelfOnboarding.types'
import {RequestAccessSchema} from './validators/RequestAccessSchema'

export const RequestAccessSelfOnboarding = () => {
  const {globalState} = useGlobalState()
  const availableCountries = useAvailableCountries()
  const requestAccessMutation = useRequestAccess()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [termsVersion, _] = useState<string | null>(null)

  // TODO: Replace with API data once options endpoint is implemented
  const backendPermissions: string[] = [
    'canSeeOrderAndDeliveries',
    'canCreateAndChangeOrders',
    'canSeeInvoices',
    'canSeeTestsAndCertificates'
  ]

  const initPermissions: PermissionType = Object.fromEntries(
    backendPermissions.map((permission) => [permission, permission === 'canSeeOrderAndDeliveries'])
  )

  const formMethods = useForm<RequestAccessSelfOnboardingFormData>({
    mode: 'onChange',
    resolver: zodResolver(RequestAccessSchema),
    defaultValues: {
      customerIds: [{id: ''}],
      permissions: initPermissions,
      email: 'testEmail@test.com',
      fullName: 'Full test name'
    }
  })

  const onSubmit = (data: RequestAccessSelfOnboardingFormData) => {
    const preferredLanguage = !globalState.lng ? getBrowserLanguage() : globalState.lng
    const selectedCountry = availableCountries?.find(
      (country) => country.countryCode === data.countryId
    )
    const defaultLocale = selectedCountry && calcDefaultLocale(selectedCountry, preferredLanguage)

    const formattedPermissions = Object.entries(data.permissions)
      .filter(([, value]) => value)
      .map(([key]) => key)

    const body: RequestAccessPayload = {
      fullName: data.fullName,
      email: data.email,
      mobileNumber: data.mobileNumber,
      permissions: formattedPermissions,
      companyName: data.companyName,
      market: data.market,
      countryId: data.countryId,
      customerIds: data.customerIds.map((customer) => customer.id),
      isTermsApprovalRequired: globalState.clientId === Product.OnSite,
      creationChannel: 'inAuthRegistration' as CreationChannel,
      creationProduct: globalState.clientId as CreationProduct,
      defaultLocale: defaultLocale,
      termsAcceptedOn: new Date().toISOString(),
      termsAcceptedVersion: termsVersion
    }

    requestAccessMutation.mutate(body)
  }

  const handleSubmit = () => {
    formMethods.handleSubmit((data) => onSubmit(data))
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        data-test-id="request-access-self-onboarding-form"
        style={{height: '100%'}}
      >
        <FormStepper onSubmit={handleSubmit} />
      </form>
    </FormProvider>
  )
}
